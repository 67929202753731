<template lang="html">
  <div class="notice">
    <van-list v-model:loading="state.loading" :finished="state.finished" finished-text="没有更多了" @load="onLoad">
      <div>
        <div class="list" @click="goTo(item)" v-for="item in listData" :key="item.property_log_id">
          <div class="dot" v-if="item.log_status == 0"></div>
          <div class="title">
            <div class="left">
              <img :src="getType(item.property_key).img" alt />
              <span>{{ getType(item.property_key).name }}</span>
            </div>
            <div class="right">
              <span>{{ item.create_at }}</span>
            </div>
          </div>
          <div class="content">
            <p class="text">{{ item.property_content }}</p>
            <div class="box" v-if="item.property_key >= 50 && item.property_key != 80">
              <div class="time">
                <span>到期时间：</span>
                {{ item.contract_end }}
              </div>
              <div class="serial">
                <span>商铺号：</span>
                {{ item.merchants_stall_numbers }}
              </div>
            </div>
            <!-- <div class="box" v-if="item.property_key < 50 ">
              <div class="time">
                <span>欠费金额：</span>{{item.key_total_price}}
              </div>
              <div class="serial">
                <span>商铺号：</span> {{item.merchants_stall_numbers}}
              </div>
            </div>-->
          </div>
          <div class="base" v-if="item.property_key != 80">
            <div class="left">查看详情</div>
            <div class="right">
              <van-icon name="arrow" />
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <van-empty v-if="listData.length == 0" class="empty-custom-image" :image="require('../../assets/image/empty.png')"
      description="还没有任何数据哦~" />
  </div>
</template>

<script>
export default {
  name: 'notice',
  data () {
    return {
      params: {
        pageNum: 0,
        pageSize: 10
      },
      state: {
        loading: false,
        finished: false
      },
      listData: []
    };
  },
  mounted () {
    // this.getList();
  },
  methods: {
    getList () {
      this.$require.HttpPost('/h5/message/list', this.params).then((res) => {
        if (res.data.list) {
          if (this.params.pageNum * this.params.pageSize >= res.data.total) {
            this.state.loading = true;
            this.state.finished = true;
          } else {
            this.state.loading = false;
          }
          this.listData = this.listData.concat(res.data.list);
        } else {
          this.state.finished = true;
        }
      });
    },
    onLoad () {
      this.params.pageNum++;
      this.getList();
    },
    goTo (item) {
      this.$require.HttpPost('/h5/message/readPropertyLog', {
        property_log_id: item.property_log_id
      });
      switch (item.property_key) {
        case 39:
        case 50:
          this.$router.push('/account/contract');
          break;
        case 10:
        case 20:
        case 30:
        case 9:
          // this.$router.push('/contribute/dailyFee');//原来的缴费页面
          this.$router.push({
            path: '/contribute/index',
            query: {
              id: item.property_id
            }
          });
          break;
        case 40:
          return this.$router.push('/contribute/rental');
        default:
      }
    },
    getType (type) {
      switch (type) {
        case 1:
          return {
            name: '异常通知',
            img: require('../../assets/image/shui.png')
          };
        case 9:
          return {
            name: '日常收费',
            img: require('../../assets/image/shui.png')
          };
        case 10:
          return {
            name: '水',
            img: require('../../assets/image/shui.png')
          };
        case 20:
          return {
            name: '电',
            img: require('../../assets/image/dian.png')
          };
        case 30:
          return {
            name: '气',
            img: require('../../assets/image/dian.png')
          };
        case 39:
          return {
            name: '合同提醒',
            img: require('../../assets/image/msgIcon.png')
          };
        case 40:
          return {
            name: '租金',
            img: require('../../assets/image/zujinIcon.png')
          };
        case 41:
          return {
            name: '欠费停用',
            img: require('../../assets/image/shui.png')
          };
        case 50:
          return {
            name: '合同消息',
            img: require('../../assets/image/msgIcon.png')
          };
        case 70:
          return {
            name: '巡逻计划',
            img: require('../../assets/image/shui.png')
          };
        case 75:
          return {
            name: '卫生异常通知',
            img: require('../../assets/image/shui.png')
          };
        case 76:
          return {
            name: '设备异常通知',
            img: require('../../assets/image/shui.png')
          };
        case 80:
          return {
            name: '投诉反馈',
            img: require('../../assets/image/shui.png')
          };
        case 999:
          return {
            name: 'App端消息',
            img: require('../../assets/image/shui.png')
          };
        default:
          return {
            name: '消息',
            img: require('../../assets/image/msgIcon.png')
          };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.notice {
  padding: 30px 24px 0 24px;

  .list {
    background: #ffffff;
    box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
    border-radius: 30px;
    padding: 0 20px;
    margin-bottom: 20px;
    position: relative;

    .dot {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 15px;
      height: 15px;
      background: red;
      border-radius: 50%;
    }

    .title {
      display: flex;
      height: 108px;
      line-height: 108px;
      align-items: center;
      justify-content: space-between;

      .left {
        img {
          width: 58px;
          height: 58px;
          margin-right: 19px;
          vertical-align: middle;
        }

        span {
          font-size: 28px;
          color: #333333;
        }
      }

      .right {
        font-size: 28px;
        color: #b9b9b9;
      }
    }

    .content {
      padding: 20px;
      box-sizing: border-box;

      // background-color: red;
      .text {
        text-align: center;
        font-size: 32px;
        font-family: PingFang-SC-Bold;
        font-weight: bold;
        color: #000000;
      }

      .box {
        margin-top: 20px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        font-size: 28px;
        color: #333333;

        p {
          line-height: 48px;
        }

        span {
          display: inline-block;
          width: 140px;
          text-align: right;
        }

        .time {
          margin-top: 10px;
        }

        .serial {
          word-break: break-all;
        }
      }
    }

    .base {
      height: 90px;
      align-items: center;
      border-top: 1px solid #efefef;
      display: flex;
      justify-content: space-between;

      .left {
        font-size: 28px;
        color: #333333;
      }

      .right {
        color: #b9b9b9;
        font-size: 28px;
      }
    }
  }
}

.empty-custom-image {
  :deep(.van-empty__description) {
    color: #529100;
  }

  :deep(img) {
    object-fit: contain;
  }
}
</style>
